import React from 'react';

const OrderCard = ({ ...props }) => {
    const { order, indexKey } = props;
    const status = order?.status.toUpperCase().replace('_', ' ') ?? "";
    return (
        <div className="card mb-3" key={"order-" + order?.status + '-' + indexKey}>
            <div className="card-body">
                <div><span>Order No</span> : <span className="order-number">{order?.orderNumber}</span></div>
                <div className='d-flex align-items-center justify-content-between'>
                    <div>Phone: <span className='phone-number'>{order?.phoneNumber}</span></div>
                    <div><span className={order?.status === 'ready' ? 'badge bg-success status' : 'badge bg-danger status'}>{status}</span></div>
                </div>
            </div>
        </div>
    );


}

export default OrderCard