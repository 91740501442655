import React, { useState } from "react";
import PizzaToppingsList from "./PizzaToppingsList";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../../reducer/cartReducer";
import { v4 as uuidv4 } from "uuid";

const SignaturePizzaItems = ({ ...props }) => {
  const {
    index,
    pizza,
    handleGetSignaturePizza,
    createEmptyObjects,
    setSection,
  } = props;

  const dispatch = useDispatch();

  let cartdata = useSelector((state) => state.cart.cart);

  const cheesePrice = Number(pizza?.cheese?.price || 0);
  const crustPrice = Number(pizza?.crust?.price || 0);
  const crustTypePrice = Number(pizza?.crust_type?.price || 0);
  const specialBasePrice = Number(pizza?.special_base?.price || 0);
  const spicePrice = Number(pizza?.spices?.price || 0);
  const saucePrice = Number(pizza?.sauce?.price) || 0;
  const cookPrice = Number(pizza?.cook?.price || 0);

  const additionalPrice =
    cheesePrice +
    crustPrice +
    crustTypePrice +
    specialBasePrice +
    spicePrice +
    saucePrice +
    cookPrice;

  const pizza_prices = pizza?.pizza_prices.filter((item) => item.price > 0);

  const [selectedPrice, setSelectedPrice] = useState({
    size: pizza_prices[0]?.size || "",
    price:
      (Number(pizza_prices[0]?.price) + Number(additionalPrice)).toFixed(2) ||
      0,
  });

  const handleSizeChange = (e) => {
    const size = e.target.dataset.size;
    const price = e.target.dataset.price;
    setSelectedPrice({ size, price });
  };

  const handleCartClick = (code) => {
    console.log(`Pizza Code: ${code}`);
    console.log(`Selected Size: ${selectedPrice.size}`);
    console.log(`Selected Price: $${selectedPrice.price}`);

    let crustObj = pizza?.crust;

    let cheeseObj = pizza?.cheese;

    let crustTypeObj = pizza?.crust_type;

    let specialbaseObj = pizza?.special_base;

    let cookObj = pizza?.spices;

    let sauceObj = pizza?.sauce;

    let spiceObj = pizza?.cook;

    let tpsTwo,
      tpsOne,
      tpsFree = [];

    tpsTwo = pizza?.topping_as_2.map((item) => {
      const newItem = {
        toppingsCode: item.code,
        toppingsName: item.title,
        toppingsPrice: Number(item.price ?? 0).toFixed(2),
        toppingsPlacement: "whole",
        pizzaIndex: 0,
        amount: Number(item.price ?? 0).toFixed(2),
      };
      return newItem;
    });

    tpsOne = pizza?.topping_as_1.map((item) => {
      const newItem = {
        toppingsCode: item.code,
        toppingsName: item.title,
        toppingsPrice: Number(item.price ?? 0).toFixed(2),
        toppingsPlacement: "whole",
        pizzaIndex: 0,
        amount: Number(item.price ?? 0).toFixed(2),
      };
      return newItem;
    });

    tpsFree = pizza?.topping_as_free.map((item) => {
      const newItem = {
        toppingsCode: item.code,
        toppingsName: item.title,
        toppingsPrice: Number(item.price ?? 0).toFixed(2),
        toppingsPlacement: "whole",
        pizzaIndex: 0,
        amount: Number(item.price ?? 0).toFixed(2),
      };
      return newItem;
    });

    const pizzaState = [
      {
        crust: {
          crustCode: pizza?.crust.code,
          crustName: pizza?.crust.title,
          price: crustObj ? Number(crustObj.price) : Number(0),
        },
        cheese: {
          cheeseCode: pizza?.cheese.code,
          cheeseName: pizza?.cheese.title,
          price: cheeseObj ? Number(cheeseObj.price) : Number(0),
        },
        crustType: {
          crustTypeCode: pizza?.crust_type.code,
          crustType: pizza?.crust_type.title,
          price: crustTypeObj ? Number(crustTypeObj.price) : Number(0),
        },
        specialBases: {
          specialbaseCode: pizza?.special_base.code,
          specialbaseName: pizza?.special_base.title,
          price: specialbaseObj ? Number(specialbaseObj.price) : Number(0),
        },
        cook: {
          cookCode: pizza?.cook.code,
          cook: pizza?.cook.title,
          price: cookObj ? Number(cookObj.price) : Number(0),
        },
        sauce: {
          sauceCode: pizza?.sauce.code,
          sauce: pizza?.sauce.title,
          price: sauceObj ? Number(sauceObj.price) : Number(0),
        },
        spicy: {
          spicyCode: pizza?.spices.code,
          spicy: pizza?.spices.title,
          price: spiceObj ? Number(spiceObj.price) : Number(0),
        },
        toppings: {
          countAsTwoToppings: tpsTwo,
          countAsOneToppings: tpsOne,
          freeToppings: tpsFree,
          isAllIndiansTps: true,
        },
      },
    ];

    const payload = {
      id: uuidv4(),
      productCode: pizza.code,
      productType: "signature_pizza",
      productName: pizza?.pizza_name,
      config: {
        pizza: pizzaState,
        sides: [],
        dips: [],
        drinks: [],
      },
      quantity: "1",
      price: selectedPrice.price,
      amount: selectedPrice.price,
      comments: "",
      pizzaSize: selectedPrice.size,
      pizzaPrice: selectedPrice.price,
    };
    dispatch(addToCart([payload, ...cartdata]));
  };

  return (
    <li className="list-group-item" key={pizza.code + "-" + index}>
      <div className="d-flex align-items-start justify-content-between flex-column px-1">
        <div className="d-flex align-items-start justify-content-between w-100">
          <div className="d-flex align-align-items-start">
            <div className="d-flex flex-column align-items-start">
              <h6 className="mb-1">
                {pizza.pizza_name}
                {pizza?.category?.category_name !== null && (
                  <span className="ms-1 text-danger-dark">
                    ({pizza?.category?.category_name})
                  </span>
                )}
              </h6>
              <PizzaToppingsList
                toppingsFree={pizza?.topping_as_free}
                toppingsOne={pizza?.topping_as_1}
                toppingsTwo={pizza?.toppings_as_2}
                index={index}
              />
            </div>
          </div>
          <div className="">
            {pizza_prices.map((pizza_price, pIndex) => {
              if (pizza_price.price > 0) {
                const price = (
                  Number(pizza_price.price) + Number(additionalPrice)
                ).toFixed(2);
                return (
                  <div className="form-check" key={`psp-${pIndex}`}>
                    <input
                      className="form-check-input"
                      type="radio"
                      name={`price-${pizza.code}`}
                      id={`price_size-${pizza.code}-${pizza_price.size}`}
                      data-size={pizza_price.size}
                      data-price={price}
                      onChange={(e) => handleSizeChange(e)}
                      defaultChecked={pIndex === 0}
                    />
                    <label
                      className="form-check-label text-medium"
                      htmlFor={`price_size-${pizza.code}-${pizza_price.size}`}
                    >
                      <span className="font-bold">{pizza_price.size}</span>
                      <span className="ms-1 text-large-pizza-price">
                        {`$${price}`}
                      </span>
                    </label>
                  </div>
                );
              }
            })}
          </div>
        </div>
        {pizza_prices.length > 0 && (
          <div className="w-100 text-end">
            <button
              type="button"
              className="btn btn-sm cart-add py-1 px-2 ms-2"
              onClick={() => handleCartClick(pizza.code)}
            >
              Add to Cart
            </button>
            <button
              type="button"
              className="btn btn-sm customize py-1 px-2 ms-2"
              onClick={() => {
                setSection("hide");
                handleGetSignaturePizza(pizza?.code);
                createEmptyObjects(Number(1));
              }}
            >
              Customize
            </button>
          </div>
        )}
      </div>
    </li>
  );
};

export default SignaturePizzaItems;
