import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  setDisplaySpecialForm,
  setDisplayOtherPizzaForm,
  setDisplaySignatureForm,
} from "../reducer/cartReducer";
import { Link } from "react-router-dom";
import signatureImg from "../assets/signature.png";
import dipsImg from "../assets/dips.png";
import drinksImg from "../assets/drinks.png";
import otherImg from "../assets/other.png";
import specialImg from "../assets/special.png";
import createImg from "../assets/create.png";
import sidesImg from "../assets/sides.png";
import { toast } from "react-toastify";

const ProductTabNav = ({ ...props }) => {
  const {
    createYourOwnRef,
    specialTabRef,
    signatureTabRef,
    otherPizzaTabRef,
    sidesRef,
    dipsRef,
    drinksRef,
    setPayloadEdit,
    payloadEdit,
    setActiveTab,
    activeTab,
    section,
  } = props;

  const dispatch = useDispatch();

  const imgStyle = {
    width: "18px",
  };

  const handleTabClick = (tabId) => {
    if (payloadEdit !== undefined) {
      toast.warn(
        "You cannot go back until you confirm or edit the selected pizza"
      );
      return false;
    }
    // Proceed to update the active tab
    setActiveTab(tabId);
    dispatch(setDisplaySignatureForm(false));
    dispatch(setDisplaySpecialForm(false));
    dispatch(setDisplayOtherPizzaForm(false));
  };

  useEffect(() => {}, [payloadEdit]);

  return (
    <ul
      className={`nav ${
        section === "hide" ? "d-none" : ""
      } nav-tabs nav-fill psTabsUl mt-2`}
      role="tablist"
    >
      <li className="nav-item">
        <Link
          ref={createYourOwnRef}
          className={`nav-link active p-2 psTabs d-flex align-items-center gap-1 justify-content-center`}
          data-bs-toggle="tab"
          onClick={() => handleTabClick("createByOwn")}
        >
          <img src={createImg} alt="Create your own" style={imgStyle} />
          Create Your Own
        </Link>
      </li>
      <li className="nav-item">
        <Link
          ref={specialTabRef}
          className={`nav-link  p-2 psTabs d-flex align-items-center gap-1 justify-content-center`}
          data-bs-toggle="tab"
          //to="#special"
          onClick={() => handleTabClick("special")}
        >
          <img src={specialImg} alt="Create your own" style={imgStyle} />
          Deals
        </Link>
      </li>
      <li className="nav-item">
        <Link
          ref={signatureTabRef}
          className={`nav-link  p-2 psTabs d-flex align-items-center gap-1 justify-content-center`}
          data-bs-toggle="tab"
          //to="#signature"
          onClick={() => handleTabClick("signature")}
        >
          <img src={signatureImg} alt="Create your own" style={imgStyle} />
          Signatures
        </Link>
      </li>
      <li className="nav-item">
        <Link
          ref={otherPizzaTabRef}
          className="nav-link p-2 psTabs d-flex align-items-center gap-1 justify-content-center"
          data-bs-toggle="tab"
          //to="#otherpizza"
          onClick={() => handleTabClick("otherpizza")}
        >
          <img src={otherImg} alt="Create your own" style={imgStyle} />
          Others
        </Link>
      </li>
      <li className="nav-item">
        <Link
          ref={sidesRef}
          className="nav-link p-2 psTabs d-flex align-items-center gap-1 justify-content-center"
          data-bs-toggle="tab"
          //to="#sidesMenu"
          onClick={() => handleTabClick("sidesMenu")}
        >
          <img src={sidesImg} alt="Create your own" style={imgStyle} />
          Sides
        </Link>
      </li>
      <li className="nav-item">
        <Link
          ref={dipsRef}
          className="nav-link p-2 psTabs d-flex align-items-center gap-1 justify-content-center"
          data-bs-toggle="tab"
          //to="#dipsMenu"
          onClick={() => handleTabClick("dipsMenu")}
        >
          <img src={dipsImg} alt="Create your own" style={imgStyle} />
          Dips
        </Link>
      </li>
      <li className="nav-item">
        <Link
          ref={drinksRef}
          className="nav-link p-2 psTabs d-flex align-items-center gap-1 justify-content-center"
          data-bs-toggle="tab"
          //to="#drinksMenu"
          onClick={() => handleTabClick("drinksMenu")}
        >
          <img src={drinksImg} alt="Create your own" style={imgStyle} />
          Drinks
        </Link>
      </li>
    </ul>
  );
};

export default ProductTabNav;
