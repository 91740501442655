import React, { useEffect, useState, useRef } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useSocket } from "../context/SocketContext";
import Nav from "./Nav";

function AuthLayout() {
  const [ok, setOk] = useState(false);
  const socket = useSocket();
  const navigate = useNavigate();
  const userData = useSelector((state) => state.user.userData);
  const role = userData?.role ?? "R_3";
  const storeLocation = userData?.storeLocation ?? "STR_1";
  useEffect(() => {
    if (userData) {
      setOk(true);
    } else {
      setOk(false);
      navigate("/");
    }
  }, [userData, navigate]);

  return (
    <div className="container-fluid mx-ayto px-0">
      <Nav />
      <div className="px-2">
        <Outlet />
      </div>
    </div>
  );
}

export default AuthLayout;
