import axios from "axios";
import { privateApi } from "./privateapis";
import { publicApi } from "./publicapis";
import AxiosPrivate from "./AxiosPrivate";

// Login, Profile & Account
export const loginApi = async (payload) => {
  return await publicApi.post(`cashier/login`, payload);
};

// Get Cashier Details using Token
export const cashierDetails = async (authToken) => {
  return await publicApi.get(`cashier/detailsByToken?token=${authToken}`);
};

export const updateProfile = async (payload) => {
  const res = await privateApi.post(`cashier/updateProfile`, payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  if (res.status === 200) {
    return res.data;
  } else {
    return res.data;
  }
};

export const changePassword = async (payload) => {
  const res = await privateApi.post(`cashier/change-password`, payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  if (res.status === 200) {
    return res.data;
  } else {
    return res.data;
  }
};

export const settingsApi = async () => {
  return await privateApi.get(`settings`);
};

export const sendNotification = async ($id) => {
  return await privateApi.get(`trial-notify?${$id}`);
};

export const updateFirebaseId = async (payload) => {
  return await privateApi.post(`cashier/updateFirebaseId`, payload);
};

export const getNotificationList = async (payload) => {
  return await privateApi.post(`cashier/order/notificationList`, payload);
};

// Get Pizza Requirements
export const allIngredientsApi = async () => {
  return await AxiosPrivate.sendGetApi(`all-ingredients`, {});
};

// Get Pizza Sides
export const sidesApi = async () => {
  return await privateApi.get(`sides`);
};

// grouped Sides
export const groupedSidesApi = async (keyword) => {
  return await publicApi.get(`type-wise-searchable-sides${keyword !== "" ? `?search=${keyword}` : ''}`);
};

// Special Offers List
export const specialPizzaApi = async () => {
  return await privateApi.get(`special-offers?cashier=1`);
};

// Special Offer Details
export const getSpecialDetailsApi = async (payload) => {
  return await privateApi.get(`special-offers/${payload}?cashier=1`);
};

// Signature Pizza List
export const allSignaturePizzasApi = async () => {
  return await privateApi.get(`signature-pizzas`);
};

// Signature Pizza Details
export const signaturePizzaApi = async (payload) => {
  return await privateApi.get(`signature-pizzas/${payload}`);
};

// Other Pizza List
export const allOtherPizzasApi = async () => {
  return await privateApi.get(`pizzas`);
};

// Other Pizza Details
export const otherPizzaApi = async (payload) => {
  return await privateApi.get(`pizzas/${payload}`);
};

export const toppingsDataByPizzaConfig = async ({ code, section }) => {
  return await privateApi.get(`pizza/toppings/${code}/${section}`)
}

// Toppings
export const toppingsApi = async () => {
  return await privateApi.get(`toppings`);
};

// Dips
export const dipsApi = async () => {
  return await privateApi.get(`dips`);
};

// SoftDrinks Data
export const softDrinksApi = async () => {
  return await privateApi.get(`soft-drinks`);
};

//Delivery Executive
export const deliveryExecutiveApi = async (storeCode) => {
  return await privateApi.get(`delivery-executive?storeCode=` + storeCode);
};

// Store Locations
export const storeLocationApi = async () => {
  return await privateApi.get(`store-locations`);
};

// Cart
export const addToCartApi = async (payload) => {
  return await privateApi.post(`cart/add`, payload);
};

export const updateCartApi = async (payload) => {
  return await privateApi.post(`cart/update`, payload);
};

export const deleteCartItemApi = async (payload) => {
  return await privateApi.post(`cart/deleteItem`, payload);
};

export const getCartListApi = async (payload) => {
  return await privateApi.post(`cart/list`, payload);
};

export const getPrevAddressApi = async (payload) => {
  return await privateApi.post(`cashier/getPrevAddress`, payload);
};

export const storeOrderSummary = async (payload) => {
  let path = `cashier/store/summary?filter_store=${payload?.store}&filter_date=${payload?.date}`;
  return await privateApi.get(path);
}

// Orders
export const postalCodeDeliverable = async (payload) => {
  return await privateApi.post(`zipcode/check/deliverable`, {
    zipcode: payload.toString(),
  });
};

export const prevOrderDetails = async (payload) => {
  return await privateApi.get(
    `customer/previousorder?mobileNumber=${payload}`
  );
};

export const pizzaPriceApi = async () => {
  return await privateApi.get(`pizza/sizes-prices`);
};


export const orderPlaceApi = async (payload) => {
  return await privateApi.post(`cashier/order/place`, payload);
};

export const orderEditApi = async (payload) => {
  return await privateApi.post(`cashier/order/edit`, payload);
};

export const orderListApi = async (payload) => {
  return await privateApi.post(`cashier/order/list`, payload);
};

export const orderDetails = async (payload) => {
  return await privateApi.post(`cashier/order/details`, payload);
};

export const statusChange = async (payload) => {
  return await privateApi.post(`cashier/order/status-change`, payload);
};

export const deliveryTypeChange = async (payload) => {
  return await privateApi.post(`cashier/order/change-delivery-type`, payload);
};

export const changeDeliveryExecutive = async (payload) => {
  return await privateApi.post(
    `cashier/order/assignDeliveryExecutive`,
    payload
  );
};

export const allDeliveryExecutiveApi = async () => {
  return await privateApi.get(`delivery-executive`);
};

export const directDeliveryTypeChange = async (payload) => {
  return await privateApi.post(
    `cashier/order/direct/change-delivery-type`,
    payload
  );
};

export const addCreditComment = async (payload) => {
  return await privateApi.post(`cashier/order/addCreditComments`, payload);
};

// Invoices (orders list)
export const getInvoicesList = async (payload) => {
  return await privateApi.post(`invoice/list`, payload);
};

export const getHighlightOrders = async (storeLocation) => {
  return await privateApi.get(`cashier/highlight/screen?storeLocation=` + storeLocation);
};

export const getAcceptScreenOrder = async (storeLocation) => {
  return await privateApi.get(`cashier/bellringer/screen?storeLocation=` + storeLocation);
};

