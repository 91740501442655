// SocketContext.js
import React, { createContext, useContext } from 'react';
import io from 'socket.io-client';
const socketBaseUrl = process.env.REACT_APP_SOCKET_BASE;
// Create a context for Socket.io
const SocketContext = createContext();

// Custom hook to use the socket context
export const useSocket = () => useContext(SocketContext);

// Socket provider component
export const SocketProvider = ({ children }) => {
    const socket = io(socketBaseUrl, {
        transports: ['websocket'],
    }); // Replace with your Socket.io server URL

    return (
        <SocketContext.Provider value={socket}>
            {children}
        </SocketContext.Provider>
    );
};
