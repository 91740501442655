import React, { useState, useEffect, useRef } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import "../css/nav.css";
import logo from "../assets/logo.png";
import profileImg from "../assets/user.png";
import { useDispatch, useSelector } from "react-redux";
import { user, setUser, setToken } from "../reducer/userReducer";
import { setEditRef, setNotification } from "../reducer/cartReducer";
import { getNotificationList, updateFirebaseId } from "../API/http";
import { toast } from "react-toastify";
import { useSocket } from "../context/SocketContext";
import { getAcceptScreenOrder } from "../API/http";

function Nav() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loginUserData, setLoginUserData] = useState(null);
  const [notifyCount, setNotifyCount] = useState(0);
  const [notifyListData, setNotifyListData] = useState([]);
  const [notifyPermission, setNotifyPermission] = useState("default");
  const [cardsData, setCardsData] = useState([]);

  const userData = useSelector((state) => state.user.userData);
  const role = userData?.role ?? "R_3";
  const socket = useSocket();
  const storeLocation = userData?.storeLocation ?? "STR_1";
  const notificationCount = useSelector(
    (state) => state.cart.notificationCount
  );
  const [hasPendingOrders, setHasPendingOrders] = useState(false);

  const loadingExistingOrder = async () => {
    try {
      const result = await getAcceptScreenOrder(storeLocation);
      if (result.status === 200) {
        let data = result.data.data;
        const placedOrders = data.filter((order) => order.status === "pending");
        setCardsData(placedOrders);
        setHasPendingOrders(placedOrders.length > 0);
      }
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  useEffect(() => {
    loadingExistingOrder();
  }, []);

  useEffect(() => {
    socket.on("onlineorder", (data) => {
      if (data?.storeCode === storeLocation) {
        setCardsData((prevCardsData) => [...prevCardsData, data]);
      }
    });

    socket.on("orderstatuschange", (data) => {
      if (data?.storeCode === storeLocation) {
        console.log(data, cardsData);
        const restOrders = cardsData.filter(
          (card) => card.orderCode !== data.orderCode
        );
        console.log("Remaining orders are", restOrders);
        setCardsData(restOrders);
      }
    });

    return () => {
      socket.off("onlineorder");
      socket.off("orderstatuschange");
    };
  }, [socket, cardsData, storeLocation]);

  useEffect(() => {
    if (cardsData.length > 0) {
      setHasPendingOrders(true);
    } else {
      setHasPendingOrders(false);
    }
  }, [cardsData]);

  //logout
  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("cashierCode");
    dispatch(setUser(null));
    dispatch(setToken(""));
    navigate("/");
  };

  //navigate to profile update page
  const handleProfileUpdate = () => {
    navigate("/profile-update");
  };

  // navigate to password update page
  const handlePasswordChange = () => {
    navigate("/password-change");
  };

  const notificationList = () => {
    const payload = {
      cashierCode: userData.code,
      orderFrom: "all",
      orderStatus: "",
    };
    getNotificationList(payload)
      .then((res) => {
        setNotifyListData(res.data.data);
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  useEffect(() => {
    setLoginUserData(userData);
  }, [userData]);

  useEffect(() => {
    notificationList();
    setNotifyCount(notificationCount?.length);
  }, [notifyCount, notificationCount]);

  return (
    <div className="position-sticky z-5 top-0 bg-white border-bottom">
      <header className="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between px-2 py-1 mb-1 ">
        <div className="col-md-3 mb-2 mb-md-0">
          <a
            href="/"
            className="d-flex align-items-center link-body-emphasis text-decoration-none"
          >
            <img
              src={logo}
              width="48px"
              height="48px"
              alt="MrSinghssPizza Logo"
              className="mx-2"
            />
            <div className="my-1 brandName">
              <span>Mr Singh's Pizza</span>
              <span className="text-secondary">(Cashier)</span>
            </div>
          </a>
        </div>

        <ul className="nav col-12 col-md-auto mb-2 justify-content-center mb-md-0">
          <li>
            <NavLink
              to="/ongoing-orders"
              className="nav-link"
              activeclassname="active"
            >
              <strong className="nav-text">New Order</strong>
            </NavLink>
          </li>
          <li>
            <NavLink to="/orders" className="nav-link" activeclassname="active">
              <strong className="nav-text">Orders</strong>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/invoices"
              className="nav-link"
              activeclassname="active"
            >
              <strong className="nav-text">Invoices</strong>
            </NavLink>
          </li>
          {role !== "R_4" ? (
            <>
              <li className="nav-item">
                <NavLink
                  to="/order-screen"
                  className="nav-link"
                  activeclassname="active"
                >
                  <strong className="nav-text">Order Screen</strong>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/order-notification"
                  className={`nav-link ${hasPendingOrders ? "border-red" : ""}`}
                  activeclassname="active"
                >
                  <strong
                    className={`nav-text ${hasPendingOrders ? "color" : ""}`}
                  >
                    Order Notifications
                  </strong>
                </NavLink>
              </li>
            </>
          ) : (
            <li className="nav-item">
              <NavLink
                to="/order-summary"
                className="nav-link"
                activeclassname="active"
              >
                <strong className="nav-text">Order Summary</strong>
              </NavLink>
            </li>
          )}
        </ul>

        <div className="col-md-3 d-flex align-items-center justify-content-center justify-content-md-end">
          <button
            className="bell-button me-3"
            type="button"
            id="notify"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            onClick={(e) => {
              dispatch(setNotification([]));
            }}
          >
            <i className="fa fa-bell-o" aria-hidden="true"></i>
            <span
              className={`notify-count ${
                notificationCount?.length === 0 ? "d-none" : ""
              }`}
            ></span>
          </button>
          <div
            className="dropdown-menu dropdown-menu-right notify-menu mt-2 m-0 p-0"
            aria-labelledby="notify"
            style={{ maxHeight: "500px", overflowY: "scroll" }}
          >
            <div className="d-flex py-3 px-4 flex-column notify-header text-white">
              <h6 className="mb-2">
                <strong>
                  <span>Notification</span>
                </strong>
              </h6>
              <p className="mb-0">
                <span>Current 10 Orders</span>
              </p>
            </div>
            {notifyListData?.map((data, index) => {
              return (
                <div
                  className="dropdown-item border-bottom p-2 px-4"
                  href="#"
                  key={"notify-" + index}
                >
                  <div className="">
                    <span className="d-inline-block fw-bold mb-1">
                      Order No:
                    </span>
                    <span className="mx-2">{data?.orderCode}</span>
                  </div>
                  <div className="">
                    <span className="d-inline-block fw-bold mb-1">
                      Store Location:
                    </span>
                    <span className="mx-2">{data?.storeName}</span>
                  </div>
                  <div>
                    <span className="d-inline-block fw-bold mb-1">
                      Grand Total:
                    </span>
                    <span className="mx-2">$ {data?.grandTotal}</span>
                  </div>
                </div>
              );
            })}
          </div>

          {/* Profile Update */}
          <button
            className="profile-button"
            type="button"
            id="profile"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <img
              src={
                loginUserData?.profilePhoto !== ""
                  ? loginUserData?.profilePhoto
                  : profileImg
              }
              alt={loginUserData?.firstName + " " + loginUserData?.lastName}
              style={{ width: "32px", height: "32px", borderRadius: "50%" }}
            />
          </button>
          <div
            className="dropdown-menu dropdown-menu-right mt-2 p-0 profile-menu"
            aria-labelledby="profile-update"
          >
            <div className="d-flex justify-content-left align-items-center p-3 profileDiv text-white">
              <img
                src={
                  loginUserData?.profilePhoto !== ""
                    ? loginUserData?.profilePhoto
                    : profileImg
                }
                alt={loginUserData?.firstName + " " + loginUserData?.lastName}
                style={{ width: "36px", height: "36px", borderRadius: "50%" }}
                className="me-1"
              />
              <div className="d-flex justify-content-center flex-column align-items-left">
                <h6>
                  {loginUserData?.firstName + " " + loginUserData?.lastName}
                </h6>
                <p className="mb-0">{loginUserData?.email}</p>
              </div>
            </div>
            <div className="dropdown-item py-2" onClick={handleProfileUpdate}>
              <i
                className="fa fa-user me-2 text-secondary"
                aria-hidden="true"
              ></i>
              <span className="">Profile Update</span>
            </div>
            <div className="dropdown-item py-2" onClick={handlePasswordChange}>
              <i
                className="fa fa-lock me-2 text-secondary"
                aria-hidden="true"
              ></i>
              <span className="">Password Change</span>
            </div>
            <div className="dropdown-item py-2" onClick={handleLogout}>
              <i
                className="fa fa-sign-out me-2 text-secondary"
                aria-hidden="true"
              ></i>
              <span className="">Logout</span>
            </div>
          </div>
        </div>
      </header> 
    </div>
  );
}

export default Nav;
