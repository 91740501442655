import React, { useEffect, useRef, useState } from "react";
import { BiChevronLeftCircle } from "react-icons/bi";
import { v4 as uuidv4 } from "uuid";
import "../../css/specialMenu.css";
import SpecialPizzaSelection from "../../components/order/SpecialPizzaSelection";
import {
  dipsApi,
  getSpecialDetailsApi,
  specialPizzaApi,
  toppingsApi,
} from "../../API/http";
import { useDispatch, useSelector } from "react-redux";
import { addToCart, setDisplaySpecialForm } from "../../reducer/cartReducer";
import { toast } from "react-toastify";
import { getSpecialDetails } from "./specialMenu/specialMenuCustomApiHandler";
import { handlePops } from "./specialMenuFunctions";
import { specialMenuParamsFn } from "./specialMenuParameters";
import LoadingLayout from "../../layout/LoadingLayout";
import getStoredState from "redux-persist/es/getStoredState";
import { get } from "jquery";

const SpecialMenu = ({
  setPayloadEdit,
  payloadEdit,
  specialTabRef,
  section,
  setSection
}) => {
  const [pizzaState, setPizzaState] = useState();
  const [show, setShow] = useState(false);
  const displaySpecialForm = useSelector(
    (state) => state.cart.displaySpecialForm
  );
  const [getSpecialData, setGetSpecialData] = useState(null);
  const [pizzaSize, setPizzaSize] = useState();
  const [pizzaSizePrice, setPizzaSizePrice] = useState(0);

  // For Data
  const [selectedLineEntries, setSelectedLineEntries] = useState();
  const [specialPizzas, setSpecialPizzas] = useState([]);
  const [crustSelected, setCrustSelected] = useState();
  const [cheeseSelected, setCheeseSelected] = useState();
  const [specialBasesSelected, setSpecialBasesSelected] = useState();
  const [toppingsData, setToppingsData] = useState();
  const [dipsData, setDipsData] = useState();
  const [dipsArr, setDipsArr] = useState([]);
  const [offeredFreeToppings, setOfferedFreeToppings] = useState(0);
  const [sidesArr, setSidesArr] = useState([]);
  const [drinksArr, setDrinksArr] = useState([]);
  const [popsArr, setPopsArr] = useState([]);
  const [comments, setComments] = useState("");
  const [price, setPrice] = useState(0);
  const [additionalToppingsCount, setAdditionalToppingsCount] = useState(0);
  const [noOfFreeDips, setNoOfFreeDips] = useState(0);
  const [noofFreeDrinks, setNoofFreeDrinks] = useState(0);
  const [freeSides, setFreeSides] = useState([]);
  const [allToppings, setAllToppings] = useState([]);
  const [totalPriceOfToppings, setTotalPriceOfToppings] = useState(0);
  const [totalPriceOfDips, setTotalPriceOfDips] = useState(0);
  const [isAllIndiansTps, setIsAllIndiansTps] = useState(false);
  const [search, setSearch] = useState("");
  const [dealType, setDealType] = useState("");
  const [pizzaCnt, setPizzaCnt] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [dipsChange, setDipsChange] = useState(0);
  const [prId, setPrId] = useState(null);
  const pizzaSizeRef = useRef(null);

  const [loading, setLoading] = useState(false);

  let calcOneTpsArr = [];
  let calcTwoTpsArr = [];

  let calcDipsArr = [];
  let calcToppingsArr = [];
  // let ides = [];
  let noOfFreeToppings = Number(getSpecialData?.noofToppings);
  let noOfFreeToppings2 = Number(getSpecialData?.noofToppings);
  let noOfAdditionalTps = Number(0);
  let noOfAdditionalTps2 = Number(0);
  const dispatch = useDispatch();
  let cartdata = useSelector((state) => state.cart.cart);

  // handle crust change
  const handleCrustChange = (event, count) => {
    const selectedValue = event.target.value;
    const selectedCrust = getSpecialData?.crust?.find(
      (option) => option.code === selectedValue
    );
    let crustObject = {
      crustCode: selectedCrust?.code,
      crustName: selectedCrust?.crustName,
      price: selectedCrust?.price,
    };
    let arr = [...pizzaState];
    arr[count - 1] = {
      ...arr[count - 1],
      crust: crustObject,
    };
    updateInCart({ pizzaState: arr });
    setPizzaState(arr);
  };

  // handle crust change
  const handleCrustTypeChange = (event, count) => {
    const selectedValue = event.target.value;
    const selectedCrustType = getSpecialData?.crustType?.find(
      (option) => option.crustTypeCode === selectedValue
    );
    let crustTypeObject = {
      crustTypeCode: selectedCrustType?.crustTypeCode,
      crustType: selectedCrustType?.crustType,
      price: selectedCrustType?.price,
    };
    let arr = [...pizzaState];
    arr[count - 1] = {
      ...arr[count - 1],
      crustType: crustTypeObject,
    };
    updateInCart({ pizzaState: arr });
    setPizzaState(arr);
  };

  // handle cheese change
  const handleCheeseChange = (event, i) => {
    const selectedValue = event.target.value;
    const selectedCheese = getSpecialData?.cheese?.find(
      (option) => option.code === selectedValue
    );
    let cheeseObject = {
      cheeseCode: selectedCheese?.code,
      cheeseName: selectedCheese?.cheeseName,
      price: selectedCheese?.price,
    };
    setPizzaState((prevPizzaState) => {
      const newArr = [...prevPizzaState];
      newArr[i - 1] = {
        ...newArr[i - 1],
        cheese: cheeseObject,
      };
      updateInCart({ pizzaState: newArr });

      return newArr;
    });
  };

  // handle special base change
  const handleSpecialBasesChange = (event, i) => {
    const selectedValue = event.target.value;
    const selectedSpecialBase = getSpecialData?.specialbases?.find(
      (option) => option.code === selectedValue
    );
    let specialBaseObject = {
      specialbaseCode: selectedSpecialBase?.code,
      specialbaseName: selectedSpecialBase?.specialbaseName,
      price: selectedSpecialBase?.price,
    };
    let arr = [...pizzaState];
    arr[i - 1] = {
      ...arr[i - 1],
      specialBases: specialBaseObject,
    };
    updateInCart({ pizzaState: arr });

    setPizzaState(arr);
  };

  const handleChangeAllIndianToppings = (e, count) => {
    if (e.target.checked) {
      const allFreeToppings = [...toppingsData?.toppings?.freeToppings].map(
        (item) => {
          let toppingsPlacement = "whole";

          return {
            toppingsCode: item?.toppingsCode,
            toppingsName: item?.toppingsName,
            toppingsPrice: Number(item?.price),
            toppingsPlacement: toppingsPlacement,
            amount: Number(0.0).toFixed(2),
          };
        }
      );
      let arr = [...pizzaState];
      arr[count - 1] = {
        ...arr[count - 1],
        toppings: {
          ...arr[count - 1].toppings,
          freeToppings: allFreeToppings,
          isAllIndiansTps: true,
        },
      };
      updateInCart({ pizzaState: arr });

      setPizzaState(arr);
      setIsAllIndiansTps(true);
    } else {
      let arr = [...pizzaState];
      arr[count - 1] = {
        ...arr[count - 1],
        toppings: {
          ...arr[count - 1].toppings,
          freeToppings: [],
        },
      };
      updateInCart({ pizzaState: arr });

      setPizzaState(arr);
      setIsAllIndiansTps(false);
    }
  };

  const handleTwoToppings = (e, count, countAsTwoToppings) => {
    const { checked } = e.target;
    delete countAsTwoToppings.image;
    delete countAsTwoToppings?.isPaid;
    delete countAsTwoToppings?.countAs;

    if (checked) {
      let arr = [...pizzaState];
      let ct = count - 1;
      let placement = "whole";
      const tempCountAsTwo = [
        ...(arr[count - 1].toppings?.countAsTwoToppings || []),
        {
          toppingsCode: countAsTwoToppings?.toppingsCode,
          toppingsName: countAsTwoToppings?.toppingsName,
          toppingsPrice: Number(countAsTwoToppings?.price),
          toppingsPlacement: placement,
          pizzaIndex: count - 1,
          amount: Number(0.0).toFixed(2),
        },
      ];
      arr[count - 1] = {
        ...arr[count - 1],
        toppings: {
          ...arr[count - 1].toppings,
          countAsTwoToppings: tempCountAsTwo,
        },
      };
      setAllToppings([...allToppings, Number(countAsTwoToppings.countAs)]);
      updateInCart({ pizzaState: arr });
      setPizzaState(arr);
    } else {
      let arr = [...pizzaState];

      let selectedObject = arr[count - 1]?.toppings?.countAsTwoToppings?.find(
        (option) => option.toppingsCode === countAsTwoToppings?.toppingsCode
      );

      const index = arr[count - 1]?.toppings?.countAsTwoToppings.findIndex(
        (item) => item.toppingsCode === countAsTwoToppings.toppingsCode
      );
      let updatedAll = allToppings.filter(
        (item) => item.toppingsCode !== countAsTwoToppings.toppingsCode
      );
      setAllToppings(updatedAll);
      if (index !== -1 && arr[count - 1]?.toppings?.countAsTwoToppings) {
        let updatedArr = arr[count - 1]?.toppings?.countAsTwoToppings.filter(
          (item) => item.toppingsCode !== countAsTwoToppings.toppingsCode
        );
        arr[count - 1] = {
          ...arr[count - 1],
          toppings: {
            ...arr[count - 1].toppings,
            countAsTwoToppings: [...updatedArr],
          },
        };
      }
      updateInCart({ pizzaState: [...arr] });

      setPizzaState([...arr]);
    }
  };

  const handleCountAsTwoToppingsPlacementChange = (
    event,
    count,
    toppingsCode
  ) => {
    const selectedValue = event.target.value;
    let arr = [...pizzaState];
    let selectedObject = arr[count - 1]?.toppings?.countAsTwoToppings?.find(
      (option) => option.toppingsCode === toppingsCode
    );
    selectedObject = {
      ...selectedObject,
      toppingsPlacement: selectedValue,
    };
    let indexOfSelectedObject = arr[
      count - 1
    ]?.toppings?.countAsTwoToppings?.findIndex(
      (option) => option.toppingsCode === toppingsCode
    );

    if (indexOfSelectedObject !== -1) {
      arr[count - 1] = {
        ...arr[count - 1],
        toppings: {
          ...arr[count - 1]?.toppings,
          countAsTwoToppings: [
            ...arr[count - 1]?.toppings?.countAsTwoToppings.slice(
              0,
              indexOfSelectedObject
            ),
            selectedObject,
            ...arr[count - 1]?.toppings?.countAsTwoToppings.slice(
              indexOfSelectedObject + 1
            ),
          ],
        },
      };
      updateInCart({ pizzaState: arr });
    }
    setPizzaState(arr);
  };

  const handleOneToppings = (e, count, countAsOneToppings) => {
    const { checked } = e.target;
    delete countAsOneToppings.image;
    delete countAsOneToppings?.isPaid;
    delete countAsOneToppings?.countAs;

    if (checked) {
      let ct = count - 1;
      let placement = "whole";

      setPizzaState((prevState) => {
        const newState = [...prevState];
        const pizzaIndex = count - 1;
        newState[pizzaIndex] = {
          ...newState[pizzaIndex],
          toppings: {
            ...newState[pizzaIndex].toppings,
            countAsOneToppings: [
              ...(newState[pizzaIndex].toppings.countAsOneToppings || []),
              {
                toppingsCode: countAsOneToppings?.toppingsCode,
                toppingsName: countAsOneToppings?.toppingsName,
                toppingsPrice: Number(countAsOneToppings?.price),
                toppingsPlacement: placement,
                pizzaIndex: count - 1,
                amount: Number(0.0).toFixed(2),
              },
            ],
          },
        };

        setAllToppings([...allToppings, Number(countAsOneToppings.countAs)]);
        updateInCart({ pizzaState: newState });
        return newState;
      });
    } else {
      setPizzaState((prevState) => {
        const newState = [...prevState];
        const pizzaIndex = count - 1;

        const index = newState[
          pizzaIndex
        ]?.toppings?.countAsOneToppings.findIndex(
          (item) => item.toppingsCode === countAsOneToppings.toppingsCode
        );

        if (index !== -1) {
          const updatedArr = newState[
            pizzaIndex
          ]?.toppings?.countAsOneToppings.filter(
            (item) => item.toppingsCode !== countAsOneToppings.toppingsCode
          );

          newState[pizzaIndex] = {
            ...newState[pizzaIndex],
            toppings: {
              ...newState[pizzaIndex].toppings,
              countAsOneToppings: [...updatedArr],
            },
          };

          setAllToppings((prevAllToppings) =>
            prevAllToppings.filter(
              (item) => item !== Number(countAsOneToppings.countAs)
            )
          );
        }
        updateInCart({ pizzaState: newState });

        return newState;
      });
    }
  };

  const handleFreeToppingsPlacementChange = (event, count, toppingsCode) => {
    const selectedValue = event.target.value;
    let arr = [...pizzaState];
    let selectedObject = arr[count - 1]?.toppings?.freeToppings?.find(
      (option) => option.toppingsCode === toppingsCode
    );
    selectedObject = {
      ...selectedObject,
      toppingsPlacement: selectedValue,
    };
    let indexOfSelectedObject = arr[
      count - 1
    ]?.toppings?.freeToppings?.findIndex(
      (option) => option.toppingsCode === toppingsCode
    );
    if (indexOfSelectedObject !== -1) {
      arr[count - 1] = {
        ...arr[count - 1],
        toppings: {
          ...arr[count - 1]?.toppings,
          freeToppings: [
            ...arr[count - 1]?.toppings?.freeToppings.slice(
              0,
              indexOfSelectedObject
            ),
            selectedObject,
            ...arr[count - 1]?.toppings?.freeToppings.slice(
              indexOfSelectedObject + 1
            ),
          ],
        },
      };
      updateInCart({ pizzaState: arr });
    }

    setPizzaState(arr);
  };

  const handleCountAsOneToppingsPlacementChange = (
    event,
    count,
    toppingsCode
  ) => {
    const selectedValue = event.target.value;
    let arr = [...pizzaState];
    let selectedObject = arr[count - 1]?.toppings?.countAsOneToppings?.find(
      (option) => option.toppingsCode === toppingsCode
    );
    selectedObject = {
      ...selectedObject,
      toppingsPlacement: selectedValue,
    };
    let indexOfSelectedObject = arr[
      count - 1
    ]?.toppings?.countAsOneToppings?.findIndex(
      (option) => option.toppingsCode === toppingsCode
    );
    if (indexOfSelectedObject !== -1) {
      arr[count - 1] = {
        ...arr[count - 1],
        toppings: {
          ...arr[count - 1]?.toppings,
          countAsOneToppings: [
            ...arr[count - 1]?.toppings?.countAsOneToppings.slice(
              0,
              indexOfSelectedObject
            ),
            selectedObject,
            ...arr[count - 1]?.toppings?.countAsOneToppings.slice(
              indexOfSelectedObject + 1
            ),
          ],
        },
      };
      updateInCart({ pizzaState: arr });
    }

    setPizzaState(arr);
  };

  const handleFreeToppings = (e, count, freeToppings) => {
    const { checked } = e.target;
    delete freeToppings.image;

    setPizzaState((prevState) => {
      const newState = [...prevState];
      const pizzaIndex = count - 1;

      if (checked) {
        let ct = count - 1;
        let placement = "whole";

        newState[pizzaIndex] = {
          ...newState[pizzaIndex],
          toppings: {
            ...newState[pizzaIndex].toppings,
            freeToppings: [
              ...(newState[pizzaIndex].toppings.freeToppings || []),
              {
                toppingsCode: freeToppings?.toppingsCode,
                toppingsName: freeToppings?.toppingsName,
                toppingsPrice: Number(freeToppings?.price),
                toppingsPlacement: placement,
                amount: Number(0.0).toFixed(2),
              },
            ],
          },
        };
      } else {
        const index = newState[pizzaIndex]?.toppings?.freeToppings?.findIndex(
          (item) => item.toppingsCode === freeToppings.toppingsCode
        );
        if (index !== -1) {
          const updatedArr = newState[
            pizzaIndex
          ]?.toppings?.freeToppings?.filter(
            (item) => item.toppingsCode !== freeToppings.toppingsCode
          );
          newState[pizzaIndex] = {
            ...newState[pizzaIndex],
            toppings: {
              ...newState[pizzaIndex].toppings,
              freeToppings: [...updatedArr],
            },
          };
        }
      }
      updateInCart({ pizzaState: newState });

      return newState;
    });
  };

  useEffect(() => {
    specialIngredients();
    dispatch(setDisplaySpecialForm(false));
  }, []);

  //Component - Special Pizza Selection
  const elements = [];
  const createEmptyObjects = (count) => {
    const emptyObjectsArray = Array.from({ length: count }, () => ({
      crust: {
        crustCode: getSpecialData?.crust[0].code,
        crustName: getSpecialData?.crust[0].crustName,
        price: getSpecialData?.crust[0].price ?? 0,
      },
      crustType: {
        crustTypeCode: getSpecialData?.crustType[0].crustTypeCode,
        crustType: getSpecialData?.crustType[0].crustType,
        price: getSpecialData?.crustType[0].price ?? 0,
      },
      cheese: {
        cheeseCode: getSpecialData?.cheese[0].code,
        cheeseName: getSpecialData?.cheese[0].cheeseName,
        price: getSpecialData?.cheese[0].price ?? 0,
      },
      specialBases: {},
      cook: {
        cookCode: getSpecialData?.cook[0]?.cookCode,
        cook: getSpecialData?.cook[0]?.cook,
        isActive: getSpecialData?.cook[0]?.isActive,
        price: getSpecialData?.cook[0]?.price,
      },
      sauce: {
        sauceCode: getSpecialData?.sauce[0]?.sauceCode,
        sauce: getSpecialData?.sauce[0]?.sauce,
        price: getSpecialData?.sauce[0]?.price,
        isActive: getSpecialData?.sauce[0]?.isActive,
      },
      spicy: {
        spicyCode: getSpecialData?.spices[0]?.spicyCode,
        spicy: getSpecialData?.spices[0]?.spicy,
        price: getSpecialData?.spices[0]?.price,
        isActive: getSpecialData?.spices[0]?.isActive,
      },
      toppings: {
        countAsTwoToppings: [],
        countAsOneToppings: [],
        freeToppings: [],
        isAllIndiansTps: false,
      },
    }));
    setPizzaState(emptyObjectsArray);
  };

  const handleCookChange = (event, count) => {
    const selectedObject = getSpecialData?.cook?.find(
      (option) => option.cookCode === event.target.value
    );
    let arr = [...pizzaState];
    arr[count - 1] = {
      ...arr[count - 1],
      cook: selectedObject,
    };
    updateInCart({ pizzaState: arr });
    setPizzaState(arr);
  };

  const handleSauseChange = (event, count) => {
    const selectedObject = getSpecialData?.sauce?.find(
      (option) => option.sauceCode === event.target.value
    );
    let arr = [...pizzaState];
    arr[count - 1] = {
      ...arr[count - 1],
      sauce: selectedObject,
    };
    updateInCart({ pizzaState: arr });
    setPizzaState(arr);
  };

  const handleSpicyChange = (event, count) => {
    const selectedObject = getSpecialData?.spices?.find(
      (option) => option.spicyCode === event.target.value
    );
    let arr = [...pizzaState];
    arr[count - 1] = {
      ...arr[count - 1],
      spicy: selectedObject,
    };
    updateInCart({ pizzaState: arr });
    setPizzaState(arr);
  };

  const handleSides = (e, sides) => {
    let { checked } = e.target;
    delete sides.image;
    if (checked) {
      let obj = {
        sideCode: sides?.code,
        sideName: sides?.sideName,
        sideType: sides?.type,
        lineCode: sides?.lineEntries[0]?.code,
        sidePrice: sides?.lineEntries[0]?.price
          ? sides?.lineEntries[0]?.price
          : 0,
        sideSize: sides?.lineEntries[0]?.size,
        quantity: 1,
        totalPrice: Number(0).toFixed(2),
      };
      updateInCart({ sidesArray: [obj] });

      setSidesArr([obj]);
    } else {
      let filteredSides = sidesArr?.filter(
        (item) => item.sideCode !== sides.code
      );
      updateInCart({ sidesArray: filteredSides });

      setSidesArr(filteredSides);
    }
  };

  const handleSidelineEntries = (e, sides) => {
    let ind = sidesArr?.findIndex((item) => item.code === sides.code);
    if (ind !== -1) {
      let selectedEntry = sides?.lineEntries?.filter(
        (item) => item.code === e.target.value
      );
      let updatedSide = {
        ...sides,
        lineEntries: selectedEntry,
      };
      let temp = [...sidesArr];
      temp[ind] = updatedSide;
      updateInCart({ sidesArray: temp });

      setSidesArr(temp);
    }
  };

  const handleDipsCount = (e, dips) => {
    delete dips.image;
    if(e.target.value>0) {
      setDipsArr((prevDipsArr) => {
        const ind = prevDipsArr.findIndex(
          (item) => item.dipsCode === dips.dipsCode
        );
        if (ind !== -1) {
          // If the dips exists, update its quantity
          const updatedDipsArr = [...prevDipsArr];
          updatedDipsArr[ind] = {
            ...prevDipsArr[ind],
            quantity: e.target.value, // Update the quantity
          };
          return updatedDipsArr;
        } else {
          // If the dips doesn't exist, add it
          return [
            ...prevDipsArr,
            {
              ...dips,
              dipsCode: dips.dipsCode,
              dipsName: dips.dipsName,
              dipsPrice: dips.price,
              quantity: e.target.value,
            },
          ];
        }
      });
      setDipsChange((prev) => prev + 1);
    } else {
      const filteredDips = dipsArr.filter((item)=>item.dipsCode !== dips.dipsCode);
      setDipsArr(filteredDips);
      setDipsChange((prev) => prev + 1);
    }
  };

  useEffect(() => {
    if (dipsChange > 0) {
      updateInCart();
    }
  }, [dipsChange]);

  const handleDrinks = (e, drink) => {
    let { checked } = e.target;
    let drinksObj = {
      drinksCode: drink.code,
      drinksName: drink.softDrinkName,
      drinksPrice: Number(0).toFixed(2),
      quantity: 1,
      totalPrice: Number(0).toFixed(2),
    };
    if (checked) {
      updateInCart({ drinksArray: [drinksObj] });

      setDrinksArr([drinksObj]);
    } else {
      let filteredDrinks = drinksArr?.filter(
        (item) => item.drinksCode !== drink.code
      );
      updateInCart({ drinksArray: filteredDrinks });

      setDrinksArr(filteredDrinks);
    }
  };

  const newPizzaComponent = () => {
    return Array.from(
      Array(Number(getSpecialData?.noofPizzas || 0)).keys()
    ).map((pizza, index) => {
      return (
        <SpecialPizzaSelection
          key={index + 1}
          count={index + 1}
          getSpecialData={getSpecialData}
          pizzaState={pizzaState}
          crustSelected={crustSelected}
          cheeseSelected={cheeseSelected}
          setCheeseSelected={setCheeseSelected}
          specialBasesSelected={specialBasesSelected}
          handleCrustChange={handleCrustChange}
          handleCrustTypeChange={handleCrustTypeChange}
          handleSpecialBasesChange={handleSpecialBasesChange}
          handleCheeseChange={handleCheeseChange}
          handleSauseChange={handleSauseChange}
          handleSpicyChange={handleSpicyChange}
          handleCookChange={handleCookChange}
          toppingsData={toppingsData}
          handleChangeAllIndianToppings={handleChangeAllIndianToppings}
          handleTwoToppings={handleTwoToppings}
          handleOneToppings={handleOneToppings}
          handleFreeToppings={handleFreeToppings}
          offeredFreeToppings={offeredFreeToppings}
          setOfferedFreeToppings={setOfferedFreeToppings}
          additionalToppingsCount={additionalToppingsCount}
          setAdditionalToppingsCount={setAdditionalToppingsCount}
          handleCountAsTwoToppingsPlacementChange={
            handleCountAsTwoToppingsPlacementChange
          }
          handleCountAsOneToppingsPlacementChange={
            handleCountAsOneToppingsPlacementChange
          }
          handleFreeToppingsPlacementChange={handleFreeToppingsPlacementChange}
        />
      );
    });
  };

  const handleBackButton = () => {
    if (
      payloadEdit !== undefined &&
      payloadEdit?.productType === "special_pizza"
    ) {
      toast.warn(
        "You cannot go back until you confirm or edit the selected pizza"
      );
      return;
    } else {
      setSection(false);
      setPrId(uuidv4());
      setGetSpecialData(null);
      setPayloadEdit();
      setShow(false);
      setSidesArr([]);
      setDrinksArr([]);
      setDipsArr([]);
      setPopsArr([]);
      setComments("");
      setQuantity(1);
      dispatch(setDisplaySpecialForm(false));
    }
  };

  const handleAddToCart = () => {
    if (
      payloadEdit !== undefined &&
      payloadEdit?.productType.toLowerCase() === "special_pizza"
    ) {
      let arr = [...pizzaState];
      pizzaState?.map((item, index) => {
        if (
          toppingsData?.toppings?.freeToppings.length ===
          item?.toppings?.freeToppings.length
        ) {
          if (arr[index]) {
            arr[index] = {
              ...arr[index],
              toppings: {
                ...arr[index].toppings,
                isAllIndiansTps: true,
              },
            };
          }
        } else {
          if (arr[index]) {
            arr[index] = {
              ...arr[index],
              toppings: {
                ...arr[index].toppings,
                isAllIndiansTps: false,
              },
            };
          }
        }
      });

      const updatedCart = cartdata.findIndex(
        (item) => item.id === payloadEdit.id
      );

      let payloadForEdit = {
        id: payloadEdit?.id,
        productCode: getSpecialData?.code,
        productName: getSpecialData?.name,
        productType: "special_pizza",
        config: {
          pizza: arr,
          sides: sidesArr,
          dips: dipsArr,
          drinks: drinksArr,
        },
        quantity: "1",
        price: price,
        amount: price,
        comments: comments,
        pizzaSize: pizzaSize,
        pizzaPrice: pizzaSizePrice,
      };

      let tempPayload = [...cartdata];
      tempPayload[0] = payloadForEdit;

      dispatch(addToCart([...tempPayload]));
      setSidesArr([]);
      setDrinksArr([]);
      setDipsArr([]);
      setPopsArr([]);
      setComments("");
      dispatch(setDisplaySpecialForm(false));
      createEmptyObjects(Number(getSpecialData?.noofPizzas));
      clearFilter();
      setPayloadEdit();
      setQuantity(1);
      setPrId(uuidv4());
      setSection("show");
      toast.success(
        `${payloadForEdit.productName} updated to cart successfully...`
      );
    } else {
      //update pizza array
      if (calcTwoTpsArr?.length > 0) {
        let arr = [...pizzaState];
        calcTwoTpsArr?.map((tpsObj) => {
          arr[tpsObj?.pizzaIndex].toppings.countAsTwoToppings = [];
        });
        calcTwoTpsArr?.map((tpsObj) => {
          arr[tpsObj?.pizzaIndex].toppings.countAsTwoToppings = [
            ...arr[tpsObj?.pizzaIndex].toppings.countAsTwoToppings,
            tpsObj,
          ];
        });
      }
      if (calcOneTpsArr?.length > 0) {
        let arr = [...pizzaState];
        calcOneTpsArr?.map((tpsObj) => {
          arr[tpsObj?.pizzaIndex].toppings.countAsOneToppings = [];
        });
        calcOneTpsArr?.map((tpsObj) => {
          arr[tpsObj?.pizzaIndex].toppings.countAsOneToppings = [
            ...arr[tpsObj?.pizzaIndex].toppings.countAsOneToppings,
            tpsObj,
          ];
        });
      }

      let arr = [...pizzaState];
      pizzaState?.map((item, index) => {
        if (
          toppingsData?.toppings?.freeToppings?.length ===
          item?.toppings?.freeToppings?.length
        ) {
          if (arr[index]) {
            arr[index].toppings.isAllIndiansTps = true; // Replace 'true' with the desired value
          }
        } else {
          if (arr[index]) {
            arr[index].toppings.isAllIndiansTps = false; // Replace 'true' with the desired value
          }
        }
      });
      setPizzaState(arr);

      let payload = {
        id: uuidv4(),
        productCode: getSpecialData?.code,
        productType: "special_pizza",
        productName: getSpecialData?.name,
        config: {
          pizza: pizzaState,
          sides: sidesArr,
          dips: dipsArr,
          drinks: drinksArr,
        },
        quantity: "1",
        price: price,
        amount: price,
        comments: comments,
        pizzaSize: pizzaSize,
        pizzaPrice: pizzaSizePrice,
      };

      dispatch(addToCart([payload, ...cartdata]));
      setSidesArr([]);
      setDrinksArr([]);
      setDipsArr([]);
      setPopsArr([]);
      setComments("");
      clearFilter();
      setAdditionalToppingsCount(0);
      setOfferedFreeToppings(Number(getSpecialData?.noofToppings));
      setPrice(Number(getSpecialData?.price));
      createEmptyObjects(Number(getSpecialData?.noofPizzas));
      setQuantity(1);
      setSection("show");
      setPayloadEdit();
      setPrId(uuidv4());
      toast.success(` ${payload.productName} added to cart successfully...`);
    }
  };

  // Customize Details
  const handleGetSpecial = (code) => {
    getSpecialDetailsApi(code)
      .then((res) => {
        const data = res.data.data;
        setGetSpecialData(data);
        toppings();
        dips(Number(data?.noofDips));
        dispatch(setDisplaySpecialForm(true));
      })
      .catch((err) => {
        console.log(" Fetching data for deals failed due to ", err);
      });
  };

  //API - Special Pizza
  const specialIngredients = () => {
    setLoading(true);
    specialPizzaApi()
      .then((res) => {
        setSpecialPizzas(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error("ERROR From Special Pizza API: ", err);
        setLoading(false);
      });
  };

  let filteredPizzas = specialPizzas.filter((deal) => {
    // Filter by search (case-insensitive)
    const matchesSearch =
      search === "" || deal.name.toLowerCase().includes(search.toLowerCase());

    // Filter by dealType
    const matchesDealType = dealType === "" || deal.dealType === dealType;

    // Filter by pizza count
    const matchesPizzaCnt =
      pizzaCnt === "" ||
      (pizzaCnt === "5+"
        ? parseInt(deal.noofPizzas) >= 5
        : parseInt(deal.noofPizzas) === parseInt(pizzaCnt));
    return matchesSearch && matchesDealType && matchesPizzaCnt;
  });

  //API - Toppings Data
  const toppings = () => {
    setLoading(true);
    toppingsApi()
      .then((res) => {
        setToppingsData(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error("ERROR From Toppings API: ", err);
        setLoading(false);
      });
  };

  //API - Dips Data
  const dips = (noofDips) => {
    setLoading(true);
    dipsApi()
      .then((res) => {
        const result = res.data;
        const dipsData = result.data || [];
        setDipsData(dipsData);
        if (dipsData.length > 0 && noofDips > 0) {
          const indexDip = dipsData[0];
          const firstDip = {
            dipsCode: indexDip.dipsCode,
            dipsName: indexDip.dipsName,
            dipsPrice: indexDip.price,
            quantity: Number(noofDips),
            totalPrice: Number(0),
            freeQuantity: Number(noofDips),
            paidQuantity: Number(0),
          };
          setDipsArr([firstDip]);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error("ERROR From Dips API: ", err);
        setLoading(false);
      });
  };

  // Calculate Price
  const calculatePrice = () => {
    if (getSpecialData !== undefined && getSpecialData !== null) {
      let totalPrice = Number(0);
      let totalOneTpsPrice = Number(0);
      let totalTwoTpsPrice = Number(0);
      let totalPriceOfDips = Number(0);
      let freeDipsCount = Number(getSpecialData?.noofDips) || Number(0);
      let size = "";
      if (pizzaSizeRef.current) {
        size = pizzaSizeRef.current.value;
        if (size !== undefined && size !== "") {
          const priceObj = getSpecialData?.pizza_prices.find(
            (item) => size === item.size
          );
          totalPrice += priceObj ? Number(priceObj.price) : Number(0);
          setPizzaSizePrice(priceObj ? Number(priceObj.price) : Number(0));
        }
      }

      let pizzaCartons = [];
      for (let i = 0; i < getSpecialData?.noofPizzas; i++) {
        pizzaCartons.push(i);
      }
      // Iterate through pizzaState
      pizzaState?.forEach((item) => {
        totalPrice += item?.cheese?.price ? Number(item?.cheese?.price) : 0;

        totalPrice += item?.crust?.price ? Number(item?.crust?.price) : 0;

        totalPrice += item?.crustType?.price
          ? Number(item?.crustType?.price)
          : 0;

        totalPrice += item?.specialBases?.price
          ? Number(item?.specialBases?.price)
          : 0;
        totalPrice += item?.cook?.price ? Number(item?.cook?.price) : 0;
        totalPrice += item?.spicy?.price ? Number(item?.spicy?.price) : 0;
        totalPrice += item?.sauce?.price ? Number(item?.sauce?.price) : 0;
        pizzaCartons.map((pizzaCarton) => {
          if (item?.toppings?.countAsOneToppings?.length > 0) {
            item?.toppings?.countAsOneToppings?.map((items) => {
              if (items.pizzaIndex === pizzaCarton) {
                if (noOfFreeToppings > 0) {
                  let tpsObj = {
                    ...items,
                    amount: 0,
                  };
                  calcOneTpsArr.push(tpsObj);
                  noOfFreeToppings--;
                  setOfferedFreeToppings((prev) => prev - 1);
                } else {
                  calcOneTpsArr.push({
                    ...items,
                    amount: Number(items?.toppingsPrice).toFixed(2),
                  });

                  noOfAdditionalTps++;
                  setAdditionalToppingsCount((prev) => prev + 1);
                }
              }
            });
          }
          if (item?.toppings?.countAsTwoToppings?.length > 0) {
            item?.toppings?.countAsTwoToppings?.map((items) => {
              if (items.pizzaIndex === pizzaCarton) {
                if (noOfFreeToppings > 1) {
                  let tpsObj = {
                    ...items,
                    amount: 0,
                  };
                  calcTwoTpsArr.push(tpsObj);
                  setOfferedFreeToppings((prev) => prev - 2);
                  noOfFreeToppings -= Number(2);
                } else if (noOfFreeToppings === 1) {
                  calcTwoTpsArr.push({
                    ...items,
                    amount: Number(items?.toppingsPrice).toFixed(2) / 2,
                  });
                  setOfferedFreeToppings((prev) => prev - 1);
                  noOfFreeToppings -= Number(1);

                  noOfAdditionalTps++;

                  setAdditionalToppingsCount((prev) => prev + 1);
                } else {
                  calcTwoTpsArr.push({
                    ...items,
                    amount: Number(items?.toppingsPrice).toFixed(2),
                  });
                  noOfAdditionalTps += Number(2);
                  setAdditionalToppingsCount((prev) => prev + 2);
                }
              }
            });
          }
        });
      });

      calcOneTpsArr?.map((tps) => {
        totalOneTpsPrice += Number(tps?.amount);
      });

      calcTwoTpsArr?.map((tps) => {
        totalTwoTpsPrice += Number(tps?.amount);
      });

      if (dipsArr.length > 0) {
        dipsArr.forEach((item) => {
          //debugger;
          if (item.hasOwnProperty("quantity")) {
            let usedFreeDips = Math.min(item.quantity, freeDipsCount); // Calculate how many free dips can be used
            freeDipsCount -= usedFreeDips; // Subtract used free dips from available free dips
            let paidQuantity = Math.max(item.quantity - usedFreeDips, 0); // Ensure no negative paid quantity
            // Calculate total price for paid dips
            let dipsAmt = Number(paidQuantity) * Number(item.dipsPrice);
            // Add totalPrice to totalDipsPrice
            totalPriceOfDips += dipsAmt;
          }
        });
      }

      totalPrice += totalOneTpsPrice;
      totalPrice += totalTwoTpsPrice;
      totalPrice += totalPriceOfToppings;
      totalPrice += totalPriceOfDips;
      totalPrice = (Number(quantity) * Number(totalPrice)).toFixed(2);
      setPrice(totalPrice);
    }
  };

  useEffect(() => {
    setOfferedFreeToppings(noOfFreeToppings);
    setAdditionalToppingsCount(noOfAdditionalTps);
    calculatePrice();
  }, [
    pizzaState,
    sidesArr,
    dipsArr,
    selectedLineEntries,
    pizzaSize,
    pizzaSizePrice,
    drinksArr,
    popsArr,
    calcOneTpsArr,
    calcTwoTpsArr,
    noOfFreeToppings,
    pizzaSizeRef,
  ]);

  useEffect(() => {
    if (!payloadEdit) {
      if (getSpecialData?.noofToppings !== undefined) {
        setOfferedFreeToppings(Number(getSpecialData?.noofToppings));
        setAdditionalToppingsCount(0);
      }
      if (getSpecialData?.noofDips !== undefined) {
        setNoOfFreeDips(Number(getSpecialData?.noofDips));
      }
      if (getSpecialData?.noofDrinks !== undefined) {
        setNoofFreeDrinks(Number(getSpecialData?.noofDrinks));
      }
      if (payloadEdit === undefined) {
        createEmptyObjects(Number(getSpecialData?.noofPizzas));
      }
      if (getSpecialData?.freesides?.length > 0) {
        setFreeSides(getSpecialData?.freesides);
      }
    }
  }, [getSpecialData]);

  const updateInCart = (data) => {
    if (getSpecialData?.code !== undefined && getSpecialData?.code !== "") {
      let dipsArray = dipsArr;
      let sidesArray = data?.sidesArray ? data?.sidesArray : sidesArr;
      let drinksArray = data?.drinksArray ? data?.drinksArray : drinksArr;
      let pizzas = data?.pizzaState ? data?.pizzaState : pizzaState;
      let pizzaQuantity = data?.quantity ? data?.quantity : quantity;

      let tempPayload = [...cartdata];

      let updatedCartId;

      let price = 0;
      let sizeofpizza = "";
      if (pizzaSizeRef.current) {
        sizeofpizza = pizzaSizeRef.current.value;
        const priceObj = getSpecialData?.pizza_prices.find(
          (item) => item.size === pizzaSizeRef.current.value
        );
        if (priceObj) {
          price = priceObj ? Number(priceObj.price) : Number(0);
        }
      }

      let calcOneTpsArr2 = [];
      let calcTwoTpsArr2 = [];
      const calculate = () => {
        let totalPrice = Number(0);
        let totalOneTpsPrice = Number(0);
        let totalTwoTpsPrice = Number(0);
        totalPrice += price;
        let pizzaCartons = [];
        for (let i = 0; i < getSpecialData?.noofPizzas; i++) {
          pizzaCartons.push(i);
        }
        // Iterate through pizzaState
        pizzas?.forEach((item) => {
          totalPrice += item?.cheese?.price ? Number(item?.cheese?.price) : 0;
          totalPrice += item?.crust?.price ? Number(item?.crust?.price) : 0;
          totalPrice += item?.crustType?.price
            ? Number(item?.crustType?.price)
            : 0;
          totalPrice += item?.specialBases?.price
            ? Number(item?.specialBases?.price)
            : 0;
          totalPrice += item?.cook?.price ? Number(item?.cook?.price) : 0;
          totalPrice += item?.spicy?.price ? Number(item?.spicy?.price) : 0;
          totalPrice += item?.sauce?.price ? Number(item?.sauce?.price) : 0;
          pizzaCartons.map((pizzaCarton) => {
            if (item?.toppings?.countAsOneToppings?.length > 0) {
              item?.toppings?.countAsOneToppings?.map((items) => {
                if (items.pizzaIndex === pizzaCarton) {
                  if (noOfFreeToppings2 > 0) {
                    let tpsObj = {
                      ...items,
                      amount: 0,
                    };
                    calcOneTpsArr2.push(tpsObj);
                    noOfFreeToppings2 -= Number(1);
                  } else {
                    calcOneTpsArr2.push({
                      ...items,
                      amount: Number(items?.toppingsPrice).toFixed(2),
                    });
                    noOfAdditionalTps2 += 1;
                  }
                }
              });
            }
            if (item?.toppings?.countAsTwoToppings?.length > 0) {
              item?.toppings?.countAsTwoToppings?.map((items) => {
                if (items.pizzaIndex === pizzaCarton) {
                  if (noOfFreeToppings2 > 1) {
                    let tpsObj = {
                      ...items,
                      amount: 0,
                    };
                    calcTwoTpsArr2.push(tpsObj);
                    noOfFreeToppings2 -= Number(2);
                  } else if (noOfFreeToppings2 === 1) {
                    calcTwoTpsArr2.push({
                      ...items,
                      amount: Number(items?.toppingsPrice).toFixed(2) / 2,
                    });
                    noOfFreeToppings2 -= Number(1);
                    noOfAdditionalTps2++;
                  } else {
                    calcTwoTpsArr2.push({
                      ...items,
                      amount: Number(items?.toppingsPrice).toFixed(2),
                    });
                    noOfAdditionalTps2 += Number(2);
                  }
                }
              });
            }
          });
        });

        if (calcTwoTpsArr2?.length > 0) {
          let arr = [...pizzas];
          calcTwoTpsArr2?.forEach((tpsObj) => {
            arr[tpsObj?.pizzaIndex] = {
              ...arr[tpsObj?.pizzaIndex],
              toppings: {
                ...arr[tpsObj?.pizzaIndex].toppings,
                countAsTwoToppings: [],
              },
            };
          });
          calcTwoTpsArr2?.forEach((tpsObj) => {
            arr[tpsObj?.pizzaIndex] = {
              ...arr[tpsObj?.pizzaIndex],
              toppings: {
                ...arr[tpsObj?.pizzaIndex].toppings,
                countAsTwoToppings: [
                  ...arr[tpsObj?.pizzaIndex].toppings.countAsTwoToppings,
                  tpsObj,
                ],
              },
            };
          });
          pizzas = arr;
        }

        if (calcOneTpsArr2?.length > 0) {
          let arr = [...pizzas];
          calcOneTpsArr2?.forEach((tpsObj) => {
            arr[tpsObj?.pizzaIndex] = {
              ...arr[tpsObj?.pizzaIndex],
              toppings: {
                ...arr[tpsObj?.pizzaIndex].toppings,
                countAsOneToppings: [],
              },
            };
          });
          calcOneTpsArr2?.forEach((tpsObj) => {
            arr[tpsObj?.pizzaIndex] = {
              ...arr[tpsObj?.pizzaIndex],
              toppings: {
                ...arr[tpsObj?.pizzaIndex].toppings,
                countAsOneToppings: [
                  ...arr[tpsObj?.pizzaIndex].toppings.countAsOneToppings,
                  tpsObj,
                ],
              },
            };
          });
          pizzas = arr;
        }

        let arr_1 = [...pizzas];
        pizzas?.map((item, index) => {
          if (
            toppingsData?.toppings?.freeToppings.length ===
            item?.toppings?.freeToppings.length
          ) {
            if (arr_1[index]) {
              arr_1[index] = {
                ...arr_1[index],
                toppings: {
                  ...arr_1[index].toppings,
                  isAllIndiansTps: true,
                },
              };
            }
          } else {
            if (arr_1[index]) {
              arr_1[index] = {
                ...arr_1[index],
                toppings: {
                  ...arr_1[index].toppings,
                  isAllIndiansTps: false,
                },
              };
            }
          }
        });
        pizzas = arr_1;

        calcOneTpsArr2?.forEach((tps) => {
          totalOneTpsPrice += Number(tps?.amount);
        });

        calcTwoTpsArr2?.forEach((tps) => {
          totalTwoTpsPrice += Number(tps?.amount);
        });

        totalPrice += totalOneTpsPrice;
        totalPrice += totalTwoTpsPrice;
        const formattedPrice = (
          Number(totalPrice) +
          Number(totalPriceOfToppings) +
          Number(totalPriceOfDips)
        ).toFixed(2);
        return formattedPrice;
      };

      let freeDipsCount = Number(getSpecialData?.noofDips) || Number(0);
      let totalDipsPrice = Number(0);
      let finalDipsArray = [];
      if (dipsArr.length > 0) {
        dipsArr?.forEach((item) => {
          let usedFreeDips = Math.min(item.quantity, freeDipsCount); // Calculate how many free dips can be used
          freeDipsCount -= usedFreeDips; // Subtract used free dips from available free dips

          let paidQuantity = Math.max(item.quantity - usedFreeDips, 0); // Ensure no negative paid quantity

          // Calculate total price for paid dips
          let dipsAmt = Number(paidQuantity) * Number(item.dipsPrice);

          // Push the item into the result array with calculated values
          finalDipsArray.push({
            ...item,
            freeQuantity: usedFreeDips,
            paidQuantity: paidQuantity,
            totalPrice: dipsAmt,
          });
          // Add totalPrice to totalDipsPrice
          totalDipsPrice += dipsAmt;
        });
      }

      let finalPrice = calculate();
      finalPrice = (Number(finalPrice) + Number(totalDipsPrice)).toFixed(2);
      let finalVal = (Number(pizzaQuantity) * finalPrice).toFixed(2);
      let payload = {
        id: prId,
        productCode: getSpecialData.code,
        productType: "special_pizza",
        productName: getSpecialData?.name,
        config: {
          pizza: pizzas,
          sides: sidesArray,
          dips: finalDipsArray,
          drinks: drinksArray,
        },
        quantity: pizzaQuantity,
        price: finalPrice,
        amount: Number(finalVal),
        comments: data?.comment ? data?.comment : comments,
        pizzaSize: sizeofpizza,
        pizzaPrice: Number(price),
      };

      if (
        payloadEdit !== undefined &&
        payloadEdit.productType === "special_pizza"
      ) {
        tempPayload = tempPayload.map((item) =>
          item.id === prId ? payload : item
        );
      } else {
        const itemExists = tempPayload.find((item) => item.id === prId);
        if (itemExists) {
          tempPayload = tempPayload.map((item) =>
            item.id === prId ? payload : item
          );
        } else {
          tempPayload.unshift(payload);
        }
      }
      dispatch(addToCart([...tempPayload]));
      setPrice(finalVal);
    }
  };

  // Changes in pizza price flow ( setPizzaSizePrice() state used ) - Developer: Shreyas Mahamuni, working date: 26-12-2023
  const handleSizeOfPizza = () => {
    if (pizzaSizeRef.current) {
      setPizzaSize(pizzaSizeRef.current.value);
      const priceObj = getSpecialData?.pizza_prices.find(
        (item) => item.size === pizzaSizeRef.current.value
      );
      if (priceObj) {
        setPizzaSizePrice(priceObj?.price);
        updateInCart({
          pizzaSize: pizzaSizeRef.current.value,
          pizzaSizePrice: priceObj ? Number(priceObj.price) : Number(0),
        });
      }
    }
  };

  let specialMenuParamsObj = specialMenuParamsFn(
    handlePops,
    {},
    {},
    updateInCart,
    setDrinksArr,
    drinksArr
  );

  const clearFilter = () => {
    setDealType("");
    setSearch("");
    setPizzaCnt("");
  };

  const handleQuantityChange = (e) => {
    setQuantity(e.target.value);
    updateInCart({ quantity: e.target.value });
  };

  useEffect(() => {
    if (
      payloadEdit &&
      payloadEdit !== undefined &&
      payloadEdit?.productType === "special_pizza"
    ) {
      setPrId(payloadEdit?.id);
      handleGetSpecial(payloadEdit?.productCode);
      setQuantity(payloadEdit?.quantity);
      setPizzaState(payloadEdit?.config?.pizza);
      setDrinksArr(payloadEdit?.config?.drinks);
      setDipsArr(payloadEdit?.config?.dips);
      setSidesArr(payloadEdit?.config?.sides);
      setPrice(Number(payloadEdit?.amount));
      setComments(payloadEdit?.comments);
      setPizzaSize(payloadEdit?.pizzaSize);
      setPizzaSizePrice(Number(payloadEdit?.pizzaPrice));
      clearFilter();
    } else {
      setPrId(uuidv4());
    }
  }, [payloadEdit]);

  return (
    <div className="d-flex flex-wrap justify-content-center">
      <div className="w-100">
        {displaySpecialForm ? (
          loading ? (
            <div>
              <LoadingLayout />
            </div>
          ) : (
            <div>
              <div className="d-flex align-items-center justify-content-between">
                {/* Back Button */}
                <button
                  type="button"
                  className="btn btn-secondary btn-xs mb-1"
                  onClick={handleBackButton}
                >
                  <BiChevronLeftCircle /> Back
                </button>
                {/* Edit to Cart Button */}
                {payloadEdit !== undefined &&
                  payloadEdit?.productType.toLowerCase() ===
                    "special_pizza" && (
                    <button
                      type="button"
                      className="btn btn-xs mb-1 addToCartbtn"
                      onClick={handleAddToCart}
                    >
                      <i className="fa fa-check"></i>
                      <span>Confirm Edit</span>
                    </button>
                  )}
              </div>
              <div className="customizablePizza px-3">
                <div className="d-flex justify-content-between">
                  <div>
                    <h6>
                      {getSpecialData?.name}
                      {getSpecialData?.subtitle !== null && (
                        <span className="ms-1 text-danger-dark">
                          ({getSpecialData?.subtitle})
                        </span>
                      )}
                    </h6>
                  </div>
                  <div className="mx-2 text-nowrap">
                    <h6>${price}</h6>
                  </div>
                </div>
                <div className="mb-1 d-flex align-items-center">
                  <p className="mb-1">
                    Toppings :
                    <strong className="mx-2">
                      {offeredFreeToppings <= 0 ? 0 : offeredFreeToppings} /
                      {getSpecialData?.noofToppings}
                    </strong>
                  </p>
                  <p className="mb-1">
                    <span>Additional Toppings Used :</span>
                    <strong className="mx-2">
                      {offeredFreeToppings <= 0 ? additionalToppingsCount : 0}
                    </strong>
                  </p>
                </div>
                <div className="mb-1">
                  <div class="row g-3 align-items-center">
                    <div class="col-auto">
                      <label htmlFor="pizzaSize" class="col-form-label">
                        Size :
                      </label>
                    </div>
                    <div class="col-auto">
                      <select
                        id="pizzaSize"
                        className="form-select my-2"
                        ref={pizzaSizeRef}
                        onChange={handleSizeOfPizza}
                        value={pizzaSize}
                      >
                        {getSpecialData?.pizza_prices.length > 0 &&
                          getSpecialData?.pizza_prices.map((pizza_price) => {
                            if (Number(pizza_price.price) > 0) {
                              return (
                                <option value={pizza_price.size}>
                                  {pizza_price.size} - ${pizza_price.price}
                                </option>
                              );
                            }
                          })}
                      </select>
                    </div>
                    <div className="col-auto">
                      <label htmlFor="quantity" class="col-form-label">
                        Quantity :
                      </label>
                    </div>
                    <div className="col-auto">
                      <input
                        type="number"
                        min={1}
                        max={20}
                        step={1}
                        value={quantity}
                        id="quantity"
                        className="form-control"
                        onChange={handleQuantityChange}
                      />
                    </div>
                  </div>
                </div>
                {newPizzaComponent()}
                {/* Sides */}
                {getSpecialData?.freesides.length === 0 ? null : (
                  <>
                    <h6 className="text-left my-1">Sides</h6>
                    <div id="sides" className="mb-1">
                      <ul className="list-group">
                        {getSpecialData?.freesides?.map((sidesData) => {
                          const comm = sidesArr.findIndex(
                            (item) => item.sideCode === sidesData.code
                          );
                          return (
                            <>
                              <li
                                className="list-group-item d-flex justify-content-between align-items-center"
                                key={sidesData.code + "sidesData"}
                              >
                                <label className="d-flex align-items-center">
                                  <input
                                    type="radio"
                                    name="sides"
                                    className="mx-3 d-inline-block"
                                    checked={comm !== -1 ? true : false}
                                    onChange={(e) => handleSides(e, sidesData)}
                                  />
                                  {sidesData.sideName}
                                  <span
                                    className={
                                      "badge-" + sidesData.type + " mx-1"
                                    }
                                  >
                                    ( {sidesData.type} )
                                  </span>
                                </label>
                                <div style={{ width: "12rem" }}>
                                  <select
                                    value={
                                      comm !== -1
                                        ? sidesArr[comm]?.lineCode
                                        : ""
                                    }
                                    className="form-select w-100 d-inline-block"
                                    onChange={(e) => {
                                      handleSidelineEntries(e, sidesData);
                                    }}
                                  >
                                    {sidesData?.lineEntries?.map(
                                      (lineEntriesData) => {
                                        return (
                                          <option
                                            key={lineEntriesData.code}
                                            value={lineEntriesData.code}
                                          >
                                            {lineEntriesData.size} - $
                                            {lineEntriesData.price}ß
                                          </option>
                                        );
                                      }
                                    )}
                                  </select>
                                </div>
                              </li>
                            </>
                          );
                        })}
                      </ul>
                    </div>
                  </>
                )}
                {/* Dips */}
                {getSpecialData?.noofDips !== "0" && (
                  <>
                    <h6 className="text-left my-1">Dips</h6>
                    <div id="dips" className="mb-1">
                      <ul className="list-group">
                        {dipsData?.map((data, index) => {
                          const comm = dipsArr?.findIndex(
                            (item) => item.dipsCode === data.dipsCode
                          );
                          return (
                            <li className="list-group-item" key={data.dipsCode}>
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="d-flex align-items-center">
                                  <label className="d-flex align-items-center">
                                    {data.dipsName} - ${data.price}
                                  </label>
                                </div>
                                <input
                                  type="number"
                                  defaultValue={
                                    index === 0 ? getSpecialData?.noofDips : 0
                                  }
                                  min={0}
                                  className="form-control mx-2"
                                  style={{ width: "75px" }}
                                  onChange={(e) => handleDipsCount(e, data)}
                                />
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </>
                )}
                {/* Drinks */}
                {getSpecialData?.pops && getSpecialData?.bottle && (
                  <>
                    {(getSpecialData?.pops.length > 0 ||
                      getSpecialData.bottle.length > 0) && (
                      <h6 className="text-left my-1">Drinks</h6>
                    )}
                    <ul id="drinks" className="list-group">
                      {getSpecialData?.pops.map((pop) => {
                        const comm = drinksArr?.findIndex(
                          (item) => item.drinksCode === pop.code
                        );

                        return (
                          <li
                            className="list-group-item d-flex justify-content-between align-items-center"
                            key={pop.code}
                          >
                            <label className="d-flex align-items-center">
                              <input
                                type="radio"
                                name="drinks"
                                className="mx-3 d-inline-block"
                                checked={comm !== -1 ? true : false}
                                onChange={(e) =>
                                  specialMenuParamsObj.handlePops.callback({
                                    ...specialMenuParamsObj.handlePops
                                      .parameters,
                                    e: e,
                                    pop: pop,
                                  })
                                }
                              />
                              {pop.softDrinkName}
                            </label>
                            <p className="mb-0 mx-2">$ {pop.price}</p>
                          </li>
                        );
                      })}
                      {getSpecialData?.bottle.map((pop) => {
                        const comm = drinksArr?.findIndex(
                          (item) => item.drinksCode === pop.code
                        );
                        return (
                          <li
                            className="list-group-item d-flex justify-content-between align-items-center"
                            key={pop.code}
                          >
                            <label className="d-flex align-items-center">
                              <input
                                type="radio"
                                name="drinks"
                                className="mx-3 d-inline-block"
                                checked={comm !== -1 ? true : false}
                                onChange={(e) => handleDrinks(e, pop)}
                              />
                              {pop.softDrinkName}
                            </label>
                            <p className="mb-0 mx-2">$ {pop.price}</p>
                          </li>
                        );
                      })}
                    </ul>
                  </>
                )}
                {/* Comments */}
                <div className="">
                  <h6 className="text-left my-1">Comments</h6>
                  <textarea
                    className="form-control"
                    rows="2"
                    cols="50"
                    value={comments}
                    onChange={(e) => {
                      updateInCart({ comment: e.target.value });
                      setComments(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
          )
        ) : (
          <div className="d-flex flex-column">
            <div className="row g-2 mb-3 ms-2">
              <div className="col-4">
                <label htmlFor="search">Search</label>
                <input
                  name="search"
                  id="search"
                  className="form-control"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
              <div className="col-3">
                <label htmlFor="type">Deal Type</label>
                <select
                  name="dealType"
                  id="dealType"
                  className="form-select"
                  value={dealType}
                  onChange={(e) => setDealType(e.target.value)}
                >
                  <option value="" key="all-deals">
                    All Deals
                  </option>
                  <option value="otherdeal" key="other-deals">
                    Other Deals
                  </option>
                  <option value="pickupdeal" key="pickup-deal">
                    Pickup Deals
                  </option>
                  <option value="deliverydeal" key="delivery-deal">
                    Delivery Deals
                  </option>
                </select>
              </div>
              <div className="col-3">
                <label htmlFor="pizzaCnt">Number of Pizzas</label>
                <select
                  name="pizzaCnt"
                  id="pizzaCnt"
                  className="form-select"
                  value={pizzaCnt}
                  onChange={(e) => setPizzaCnt(e.target.value)}
                >
                  <option value="" key="pz-cnt-">
                    All
                  </option>
                  <option value="1" valukey="pz-cnt-1">
                    1
                  </option>
                  <option value="2" valukey="pz-cnt-2">
                    2
                  </option>
                  <option value="3" valukey="pz-cnt-3">
                    3
                  </option>
                  <option value="4" valukey="pz-cnt-4">
                    4
                  </option>
                  <option value="5+" valukey="pz-cnt-5+">
                    5+
                  </option>
                </select>
              </div>
              <div className="col-2 text-end">
                <button
                  className="btn btn-xs btn-outline-danger mt-3"
                  onClick={clearFilter}
                >
                  <i className="fa fa-search"></i> Clear
                </button>
              </div>
            </div>
            <ul key="deals-pizza" className="list-group scroll-y h-70vh">
              {filteredPizzas?.map((specialPizza, index) => {
                if (specialPizza?.pizza_prices.length > 0) {
                  return (
                    <li
                      className="list-group-item"
                      key={specialPizza.code + "-" + index}
                    >
                      <div className="d-flex align-items-start justify-content-between px-1">
                        <div className="d-flex align-align-items-start">
                          <div className="d-flex flex-column">
                            <h6 className="mb-1">
                              {specialPizza.name}
                              {specialPizza?.subtitle !== null && (
                                <span className="ms-1 text-danger-dark">
                                  ({specialPizza?.subtitle})
                                </span>
                              )}
                            </h6>
                            <span>{specialPizza.noofToppings} Toppings</span>
                            <span>{specialPizza.noofPizzas} Pizzas</span>
                          </div>
                        </div>
                        <div className="d-flex flex-column align-items-end">
                          <h6>
                            {specialPizza?.pizza_prices.length > 0 &&
                              specialPizza?.pizza_prices.map(
                                (pizza_price, pIndex) => {
                                  if (pizza_price.price > 0) {
                                    return (
                                      <p
                                        className="m-0 mb-1 p-0 text-end"
                                        key={"spp" + pIndex}
                                      >
                                        {pizza_price.size}
                                        <span className="ms-1 text-large-pizza-price">
                                          ${Number(pizza_price.price)}
                                        </span>
                                      </p>
                                    );
                                  }
                                }
                              )}
                          </h6>
                          <button
                            type="button"
                            className="btn btn-sm customize py-1 px-2"
                            onClick={() => {
                              setSection("hide");
                              handleGetSpecial(specialPizza?.code);
                              createEmptyObjects(
                                Number(getSpecialData?.noofPizzas)
                              );
                            }}
                          >
                            Customize
                          </button>
                        </div>
                      </div>
                    </li>
                  );
                }
              })}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default SpecialMenu;
