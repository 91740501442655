import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Nav from '../../layout/Nav';
import { storeLocationApi, storeOrderSummary } from '../../API/http';
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import moment from 'moment';

const OrderSummary = () => {
  const user = useSelector((state) => state.user.userData);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(null);
  const [queryDate, setQueryDate] = useState(moment().add(-1, 'days').format('YYYY-MM-DD'));
  const [queryStoreLocation, setQueryStoreLocation] = useState(null);
  const [storeLocations, setStoreLocations] = useState([]);
  const [data, setData] = useState([]);

  const columns = [
    {
      name: "Store Name",
      selector: (row) => {
        return <span>{row?.store_name}</span>;
      },
    },
    {
      name: "Total Orders",
      selector: (row) => {
        return <span>{row?.total_orders}</span>;
      },
    },
    {
      name: "Total Amount",
      selector: (row) => {
        return <span>{row?.total_amount}</span>;
      },
    },
    {
      name: "Online",
      selector: (row) => {
        return <span>{row?.online_orders}</span>;
      },
    },
    {
      name: "In Store",
      selector: (row) => {
        return <span>{row?.in_store_orders}</span>;
      },
    },
  ];

  const fetchSummary = async () => {
    try {
      setLoading(true);

      const payload = {
        store: queryStoreLocation ? queryStoreLocation : "",
        date: queryDate ? queryDate : ""
      };

      await storeOrderSummary(payload)
        .then((response) => {
          setData(response.data.data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false)
        });
    } catch {
      setLoading(false);
    }
  };

  //search records on button click
  const handleSearch = async () => {
    fetchSummary();
  }

  const loadStoreLocations = async () => {
    await storeLocationApi({ store: queryStoreLocation, date: queryDate })
      .then((res) => {
        setStoreLocations(res.data.data);
      })
      .catch((err) => {
        toast.error(err?.response?.data.message);
      });
  };

  useEffect(() => {
    loadStoreLocations();
    fetchSummary();
  }, []);

  return (
    <div className="row">
      <div className="col-12">
        <form className="row border-bottom mb-3">
          <div className="col-lg-2">
            <label className="my-2">Date</label>
            <input
              type="date"
              className="mb-3 form-control"
              value={queryDate}
              onChange={(e) => {
                setQueryDate(e.target.value);
              }}
            />
          </div>

          <div className="col-lg-2">
            <label className="my-2">Store Location</label>
            <select
              className="mb-3 form-select"
              defaultValue=""
              onChange={(e) => setQueryStoreLocation(e.target.value)}
            >
              <option value="">-- Choose Store Location --</option>
              {storeLocations?.map((data) => {
                return (
                  <option value={data?.code}>
                    {data?.storeLocation}
                  </option>
                );
              })}
            </select>
          </div>

          <div className="col-lg-2 my-2 d-flex justify-content-center align-items-end">
            <button
              type="button"
              className="mb-2 btn btn-sm bg-secondary text-white px-3 fw-bold"
              onClick={handleSearch}
            >
              Search
            </button>
          </div>
        </form>
      </div>
      <div className="col-12">
        <DataTable
          columns={columns}
          data={data}
          progressPending={loading}
          pagination
          paginationServer
        />
      </div>
    </div>
  )
}

export default OrderSummary